(function() {
  'use strict';

  window.checkFinancialPeriod = function() {
    // Don't show CH and generic 'not a year' notifications at the same time
    var reg = $("#company_company_reg_no").val();
    if (typeof(reg) === 'undefined' || reg.length === 0) {
      checkProgramRange();
    } else {
      // _companies_house.js
      checkFinancialPeriodAgainstCompaniesHouse();
    }
  }

  window.checkProgramRange = function() {
    var date_start = getDateFromDateTimePicker($('#company_prog_date_start'));
    var date_end = getDateFromDateTimePicker($('#company_prog_date_end'));

    if (date_start && date_end && !areProgramDatesValid(date_start, date_end)) {
      addHelpMessageUnlessExists(
        'Financial Period',
        HELP_WARNING,
        'Your financial period is not currently set to a year', 'not-a-year'
      );
    }
  }

  function areProgramDatesValid(start, end) {
    return getProgramEndDateFromStart(start).isSame(end);
  }

  document.addEventListener('turbo:load', errors.tryFn(function() {
    if ($('#nominal_ledger_table').length === 0) { return }
    initBootgridTable($('#nominal_table'), -1, true, [
      { id : 'show_empty', name : 'No Balance', visible : true },
      { id : 'show_inactive', name : 'Inactive', visible : true }
    ]);

    afterDatepickersComponentHaveBeenInitialised(initProgramDateFields);

    getJsonOrShowMaintenanceModal('/nominal_accounts/nominals_with_error_debit_balance.json', function(nominal_accounts) {
      $.each(nominal_accounts, function(index, nominal_account) {
        addHelpMessageWithTitle(
          nominal_account['nominal_code'] + ' ' + nominal_account['name'],
          HELP_WARNING, 'It is unlikely this category would have a debit balance'
        );
      });
    });

    getJsonOrShowMaintenanceModal('/nominal_accounts/nominals_with_error_credit_balance.json', function(nominal_accounts) {
      $.each(nominal_accounts, function(index, nominal_account) {
        addHelpMessageWithTitle(
          nominal_account['nominal_code'] + ' ' + nominal_account['name'],
          HELP_WARNING, 'It is unlikely this category would have a credit balance'
        );
      });
    });

    $('#show_all_categories_button').on('click', function(e) {
      e.preventDefault();
      $('#nominal_table').bootgrid('fetchWithoutFilters');
    });

  }));
})();
