(function() {
  'use strict';

  window.showSplitsModal = function(event) {
    var row_id = getClosestBtRowIdToEvent(event);
    $('#description_field'+row_id).typeahead('destroy');
    _getSplitsModalForRow(row_id).modal('show');
    _initSplitModalCells(row_id);
    return false;
  }

  window.removeSplits = function(bt_row_id) {
    removeSplitsFromSplitModal(
      _getSplitsModalForRow(bt_row_id),
      $('#description_field'+bt_row_id)
    );
  }

  function _getClosestSplitsModalToEvent(event){
    var $modal = $(event.target).closest('.bank_transaction_splits_modal');
    if ( $modal.length ) {
      return $modal;
    } else {
      throw new SplitTransactionModalError(
        '_getClosestSplitsModal: No modal found for event type ' + event.type +
        ' with target ' + event.target.id
      )
    }
  }

  function _getSplitsModalForRow(bt_row_id){
    return returnElementOrRaiseError(
      $('#splits-modal' + bt_row_id),
      '_getSplitsModalForRow',
      bt_row_id
    )
  }

  function _initSplitModalCells(bt_row_id, row) {
    if (row) {
      var section = $(row);
    } else {
      var section = _getSplitsModalForRow(bt_row_id);
      _addSplitModalEventListeners(_getSplitsModalForRow(bt_row_id), withRowId);
    }
    var cells = section.find('td');
    updateSplitBalance(section);
    _addSplitRowEventListeners(cells, withRowId);
    checkNeedForNewNominalOption(cells);

    if ($(section).find('.split_currency_field').length > 0) {
      $(section).find('.split_currency_field').trigger('change');
    }

    // FARMPLAN: This is already handled by Stimulus
    // _useParentDescriptionAsDefaultSplitLineItemsDescription(section);

    /*
    * We're adding lots of event listeners that need to know the value of bt_row_id
    * This lets us do that succinctly
    */
    function withRowId(fn){
      return function(e) {
        fn(e, bt_row_id)
      }
    }
  }

  function _addSplitModalEventListeners($modal, withRowId){
    $modal.find('.modal-save-btn').off().on('click', withRowId(_saveSplitsAndAlterBtRow));
    $modal.find('.modal-close-btn').off().on('click', withRowId(_unsplitBtRow));
  }

  function _addSplitRowEventListeners(cells, withRowId){
    [ [ '.split_account', 'change', _splitAccountOnChange ],
      [ 'input.split_description_tf', 'change', _splitDescriptionOnChange ],
      [ '.split_net_amount_field', 'change', splitSetTaxAndTotalAmountsFromNet ],
      [ '.split_tax_code', 'change', splitSetAmountsFromTaxCode ],
      [ '.split_tax_amount_field', 'change', performAmountChecksAndUpdateBalancesForSplit ],
      [ '.split_total_amount_field', 'change', splitSetNetAndTaxAmountsFromTotal ],
      [ '.add_new_split_row', 'click', withRowId(_addNewSplitRow) ],
      [ '.remove_split_row', 'click', _removeSplitRow ],
      [ '.split_net_amount_field', 'focus', highlightCell ],
      [ '.split_tax_amount_field', 'focus', highlightCell ],
      [ '.split_total_amount_field', 'focus', highlightCell ],
      [ '.split_currency_field', 'change', withRowId(_splitCurrencyChanged) ]
    ].forEach(function(args) {
      errors.tryOn(cells.find(args[0]).off(args[1]).on(args[1], args[2]));
    });
  }

  function _splitCurrencyChanged(event, bt_row_id) {
    _setConversionRateForSplits(event, bt_row_id);
    _restrictAccountsByCurrency(event);
  }

  function _setConversionRateForSplits(event, bt_row_id) {
    var $splits_currency_field = $(event.target);
    _checkSecondAccountCurrencyAndEnableConversionRateField({
      bank_account_currency_id: $('#bank_account_field' + bt_row_id).data('currencyId'),
      splits_currency_id:  $splits_currency_field.val(),
      splits_currency_field: $splits_currency_field,
      base_currency_id: companySettings.base_currency.id,
      conversion_rate_field: $('#conversion_rate_field' + bt_row_id),
      date_field: $('#date_field' + bt_row_id)
    })
  }

  function _checkSecondAccountCurrencyAndEnableConversionRateField(arg){
    checkCurrencyForSecondAccount({
      bt_currency_id: arg.bank_account_currency_id,
      other_currency_id: arg.splits_currency_id,
      other_currency_code: arg.splits_currency_field.find('option:selected').text(),
      base_currency_id: arg.base_currency_id,
      conversion_rate_field: arg.conversion_rate_field,
      date: dateFromElement(arg.date_field)
    });
  }

  // As this is a BankPayment or BankReceipt, the bank transaction currency_id will be the bank account currency_id
  // All the splits have to use the same currency (which might be different from the bank transaction's currency id,
  // and can be any currency shown in the dropdown)
  function _restrictAccountsByCurrency(event) {
    var currency_id = $(event.target).val(); // Currency_id that's just been chosen for a split's category
    var $selects = _getClosestSplitsModalToEvent(event).find('.split_account');
    _enableSelectOptionsWithMatchingCurrencies($selects, currency_id);
    _disableSelectOptionsWithNonMatchingCurrencies($selects, currency_id);
    $selects.trigger("chosen:updated");
  }

  function _enableSelectOptionsWithMatchingCurrencies($selects, currency_id){
    $selects
      .find('*[data-currency-id="' + currency_id + '"]')
      .removeAttr("disabled");
  }

  function _disableSelectOptionsWithNonMatchingCurrencies($selects, currency_id){
    $selects
      .find('*[data-currency-id]').not('*[data-currency-id="' + currency_id + '"]')
      .attr("disabled","disabled")
      .removeAttr("selected");
  }

  function _splitAccountOnChange(event) {
    const $category_field = $(event.target);
    const date = $category_field.parents('tr.batch_row').find('.datepicker input').val();
    const $tax_code_field = getClosestSplitRowToEvent(event).find('.split_tax_code_field');
    const isLocked = 'true' == $category_field.parents('tr.batch_row')
      .find('.is-locked').val()

    if (isLocked) { return }

    updateTaxCodesDropdownBT($category_field, date, $tax_code_field);
  }

  function _splitDescriptionOnChange(event){
    updatePopoverText(event);
    _setParentBtDescription(event);
  }

  function _setParentBtDescription(event){
    splitsSetDescriptionForParentRow({
      row: getClosestBtRowToEvent(event),
      modal: _getClosestSplitsModalToEvent(event),
      description: 'bank transaction'
    });
  }

  function _addNewSplitRow(event, bt_row_id) {
    addNewSplitRow(
      _getSplitsModalForRow(bt_row_id),
      _initSplitModalCellsForNewRow(bt_row_id),
      { index_key: 'split_bank_transaction_line_items_attributes' }
    );
  }

  function _initSplitModalCellsForNewRow(bt_row_id) {
    return function(clone){
      _initSplitModalCells(bt_row_id, clone);
    }
  }

  function _removeSplitRow(event) {
    removeSplitRow(
      event,
      _getClosestSplitsModalToEvent(event),
      getClosestBtRowToEvent(event),
      'bank transaction',
      removeClosestRow
    )
  }

  function _saveSplitsAndAlterBtRow(event, bt_row_id){
    var $row = $('#bt__row' + bt_row_id); //FARMPLAN: Added row variable
    _setNetTaxTotalAmountsForBtRow($row);
    updateRunningBalances(bt_row_id);

    // FARMPLAN: stimulus won't listen to jQuery events. See line-item-row
    $row.find('.is_split_field')[0].dispatchEvent(new Event('switch'))
  }

  function _setNetTaxTotalAmountsForBtRow(bt_row) {
    splitsSetNetTaxTotalAmountsForParentRow($(bt_row), '_amount_field');
    $(bt_row).trigger('change');
  }

  function _unsplitBtRow(event, bt_row_id) {
    var $row = $('#bt__row' + bt_row_id); //FARMPLAN: Added row variable
    $('#is_split_field'+bt_row_id).val(false).trigger('change');

    // FARMPLAN: stimulus won't listen to jQuery events. See line-item-row
    $row.find('.is_split_field')[0].dispatchEvent(new Event('switch'))
    $row.find('.is_split_field').trigger('change') // FP fix unsplitting
  }

  // FARMPLAN: This is already handled by Stimulus
  // function _useParentDescriptionAsDefaultSplitLineItemsDescription($section) {
  //   var parentDescription = $section.parents('tr').find('.description_tf').data('original-description');
  //
  //   $section
  //     .find(".split_description_tf")
  //     .filter(function(i, element) { return $(element).val() == ""; })
  //     .val(parentDescription)
  //     .trigger('change');
  // }
})();
