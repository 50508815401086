/**
 * @fileoverview
 *
 * This file contains all the scripting relevant to bank transactions, in
 * in particular batch rows. Understand- each batch row has an 'id' data
 * attribute, and by using this you can construct the id for any particular
 * row's fields. The same applies to the invoice modal. $('#id') is the fastest
 * way for JQuery to find dom elements.
 */
(function() {
  window.BANK_RECEIPT = "BankReceipt";
  window.BANK_PAYMENT = "BankPayment";
  window.SALES_RECEIPT = "SalesReceipt";
  window.PURCHASE_PAYMENT = "PurchasePayment";

  /**
   * This applies to bank_transactions#new, bank_transactions#edit and imported_bank_transactions#batch
   */
  window.initBatchBankTransactionsForm = errors.tryFn(function() {
    initBtFieldStateController();
    var cells_with_events = $('#bank-transaction-rows').find('td');
    registerTransactionTableCellEvents(cells_with_events);
    errors.tryClick($('#form-submit'), preprocessBatchFormForSubmit);
    initQuickConverter(cells_with_events);
    disableTaxAmountIfTaxCodeIsZero($('tr.batch_row'), '.tax_amount_field', '.tax_code_field')
  });

  /**
   * Init a newly inserted row
   * @param row_id
   */
  window.initNewBatchRow = function(row_id) {
    var $new_row = $('#bt__row' + row_id);
    if ( $new_row.length === 0 ) { return; } // The ajax request completed, but we've already left the page, and this row isn't here anymore
    var new_cells = $new_row.find('td');

    checkNeedForNewNominalOption(new_cells);
    checkNeedForNewProjectOption(new_cells);
    checkNeedForNewTaxCodeOption(new_cells);
    checkNeedForNewStockItemOption(new_cells);
    disableTaxAmountIfTaxCodeIsZero($new_row, '.tax_amount_field', '.tax_code_field');

    initChosenSelects(new_cells);


    registerTransactionTableCellEvents(new_cells);
    initQuickConverter(new_cells);
    initDatepickerComponent($('#bt_date_cell' + row_id));

    var date_field = $('#date_field' + row_id);
    if (!date_field.prop('readonly')) {
      date_field.focus();
    }
    enableFields($('#form-submit'));
  };

  window.getBtAccountSelect = function(row_id) {
    return $("#account_field" + row_id);
  };

  window.getBtType = function(row_id) {
    return $('#type_field' + row_id).val();
  }
  window.getClosestBtRowToEvent = function(event){
    return $(event.currentTarget).closest('tr.batch_row');
  }

  window.getClosestBtRowIdToEvent = function(event){
    var $row = getClosestBtRowToEvent(event);
    if ($row.length) {
      return $row.data('id');
    }
  }

  window.btTypeIsInvoices = function(btType) {
    return btType == SALES_RECEIPT || btType == PURCHASE_PAYMENT;
  }

  /**
   * Registers events for passed set of cells. This is a lot of dom searching
   * so it is very important the 'cells' be as specific as possible
   * TODO: could move event init for running balances to the toggle
   *
   * @param cells
   */
  function registerTransactionTableCellEvents(cells) {
    var type_selects = cells.find('.bt_type_field');
    type_selects.on('change', errors.tryFn(transactionTypeChange));

    [ [ '.invoice_button', 'click', showInvoiceModal ],
      [ '.splits_button', 'click', splitsButtonClick ],
      [ '.account', 'change', accountOnChange ],
      [ '.bt_currency_field.show-disabled-select', 'change', btCurrencyChange ],
      [ '.conversion_rate_field', 'change', updateRunningBalancesForRow ],
      [ '.net_amount_field', 'change', performAmountChecksAndUpdateBalances ],
      [ '.tax_code', 'change', performAmountChecksAndUpdateBalances ],
      [ '.tax_amount_field', 'change', performAmountChecksAndUpdateBalances ],
      [ '.total_amount_field', 'change', btTotalAmountChange ],
      [ '.add_new_bt_row', 'click', addRow ],
      [ '.bt__remove_row', 'click', removeRow ],
      [ '.description_tf', 'change', updatePopoverText ],
      [ '.net_amount_field', 'focus', highlightCell ],
      [ '.tax_amount_field', 'focus', highlightCell ],
      [ '.total_amount_field', 'focus', highlightCell ],
      [ '.is_split_field', 'change', onSplitStatusChange ],
      [ '.attachment_button', 'click', openBatchUploadsModal ],
      [ '.submit_row_button', 'click', submit_row]
    ].forEach(function(args) {
      errors.tryOn(cells.find(args[ 0 ]), args[ 1 ], args[ 2 ]);
    });

    onceAfterDatepickersComponentHaveBeenInitialised(function(){
      // dp.change gets triggered when the datepicker is being initialised,
      // wait until after that to add the event listener
      errors.tryOn(cells.find('.datepicker-component'), 'dp.change', btDateOnChange)
    });

    $.each(cells.find('input.typeahead'), function(_, desc_field) {
      typeAheadInput($(desc_field), $('.typeahead_descriptions'));
    });

    hideIndicatorWhenMissingFieldIsSelected();
  }

  function btDateOnChange(event) {
    setConversionRateForRow(event);
    dateWarnings(event);
    checkForDuplicateBankTransfers(event);
    checkForPotentialDuplicates(event);
    updateTaxCodeOptionsForRow(event);
  }

  function updateTaxCodeOptionsForRow(event) {
    const $date_picker = $(event.target);
    const $row = $date_picker.parents('tr');
    const $category_field = $row.find('.account');
    const selected_category = $category_field.find(':selected').data('global-id');
    const $tax_code_fields = $($row.find('select.tax_code_field, select.split_tax_code_field'));

    if (selected_category === undefined) {
      updateTaxCodeOptions($date_picker, $tax_code_fields);
    } else {
      let date = $row.find('.datepicker input').val();
      updateTaxCodesDropdownBT($category_field, date, $tax_code_fields);
    }
  }

  function btTotalAmountChange(event) {
    updateBalancesAndCheckForInvoices(event);
    checkForDuplicateBankTransfers(event);
    checkForPotentialDuplicates(event);
  }

  function dateWarnings(event) {
    var $elem = $(event.currentTarget);
    addDatePeriodWarning($elem);
    addDateInFutureWarning($elem);
    addInvoiceDateWarning($elem);
  }

  function setCurrencyForBankTransaction(row_id) {
    if ($('#bt__row' + row_id + ' .bt__currency:visible').length == 0) {
      return;
    }
    var btType = getBtType(row_id);
    var bank_account = $('#bank_account_field' + row_id);
    setCurrencyForBankTransactionType(row_id, bank_account, btType);
  }

  function setCurrencyForBankTransactionType(row_id, bank_account, btType) {
    var currency_select = $('#currency_field' + row_id);
    var hidden_currency_field = $('#hidden_currency_field' + row_id);
    var date = getDateForRow(row_id);
    var base_currency_id = companySettings.base_currency.id;
    var conversion_rate_field = $("#conversion_rate_field" + row_id);
    var bank_account_currency_id = bank_account.data('currencyId');
    var account_currency_id = getBtAccountSelect(row_id).find("option:selected").data("currencyId");
    if (btTypeIsBankTransfer(btType)) {
      updateCurrencyValue(bank_account_currency_id, currency_select, hidden_currency_field);
      checkCurrencyForSecondAccount({
        bt_currency_id: bank_account_currency_id,
        other_currency_id: account_currency_id,
        other_currency_code: $('#currency_field' + row_id + ' option[value="' + account_currency_id + '"]').text(),
        base_currency_id: base_currency_id,
        conversion_rate_field: conversion_rate_field,
        date: date
      });
    } else if (btTypeIsInvoices(btType)) {
      updateCurrencyValue(account_currency_id || bank_account_currency_id, currency_select, hidden_currency_field);
      var bank_account_currency_code = bank_account.data('currencyCode');
      checkCurrencyForSecondAccount({
        bt_currency_id: account_currency_id,
        other_currency_id: bank_account_currency_id,
        other_currency_code: bank_account_currency_code,
        base_currency_id: base_currency_id,
        conversion_rate_field: conversion_rate_field,
        date: date
      });
    }
  }

  function getDateForRow(row_id){
    var dp = getClosestDateTimePicker($("#date_field" + row_id))
    return dp && dp.date();
  }

  window.btTypeIsBankTransfer = function(btType) {
    return btType == BANK_PAYMENT || btType == BANK_RECEIPT;
  }

  function updateCurrencyValue(currency_id, currency_select, hidden_currency_field) {
    currency_select.val(currency_id).change();
    currency_select.trigger("chosen:updated");
    hidden_currency_field.val(currency_id).change();
  }

  /**
   * Changes columns as appropriate for transaction type
   * @param event
   */
  function transactionTypeChange(event) {
    var row_id = getClosestBtRowIdToEvent(event);
    var type = $(event.currentTarget).val();
    emptyModalIfTypeHasChanged(row_id, type);
    setCurrencyForBankTransaction(row_id);
    saveBankTransactionDescription(row_id);
    checkInvoiceExistsWithAmountsAndNominalAccount(event.currentTarget);
    selectCategoryIfSingleOption(row_id, type);
    hideFieldIndicators($(event.target).parents('tr').find('.bt__account select').parents('.form-group'));
  }

  function selectCategoryIfSingleOption(row_id, type) {
    var account_select = getBtAccountSelect(row_id);
    var select_options = account_select.find(".bt_account_option_for_" + type);

    if (select_options.length === 1) {
      account_select.val(select_options[0].value).change();
      account_select.trigger("chosen:updated");
    }
  }

  function saveBankTransactionDescription(row_id) {
    var btType = getBtType(row_id);
    if (btTypeIsInvoices(btType)) {
      var $description_field = $('#description_field' + row_id);
      $description_field.prop("defaultValue", $description_field.val());
    }
  }

  // PANDLE OVERRIDE - fix a category tax code change bug
  function accountOnChange(event) {
    const $category_field = $(event.target);
    const $row = $category_field.parents('tr');
    const date = $row.find('.datepicker input').val();
    const $tax_code_fields = $($row.find('select.tax_code_field, select.split_tax_code_field'));
    const isLocked = $row.find('.is-locked').val() == 'true'

    if (isLocked) { return }

    updateTaxCodesDropdownBT($category_field, date, $tax_code_fields, () => {
      const row_id = $row.data('id');
      updateRunningBalances(row_id);
      setCurrencyForBankTransaction(row_id);
      checkForPotentialDuplicates(event);
      checkForDuplicateBankTransfers(event);
      if (btTypeIsInvoices(getBtType(row_id))) { checkForMatchingInvoices(row_id); };
      hideFieldIndicators($category_field.parents('.form-group'));
      $tax_code_fields.trigger('change') // FP - fix total not updated on cat change
    });
  }

  /**
   * Only set the conversion rate if bank account and category are both set
   * @param event
   */
  function btCurrencyChange(event) {
    var accountSelect = getAccountFieldForRow(getClosestBtRowToEvent(event));

    if (accountSelect.val() && getCurrencyIdForAccountField(accountSelect)) {
      setConversionRateForRow(event);
    }
  }

  function getAccountFieldForRow($row){
    return getBtAccountSelect($row.data('id'))
  }

  function getCurrencyIdForAccountField($field) {
    return $field.find('option:selected').data().currencyId;
  }

  function splitsButtonClick(event){
    $('#is_split_field'+getClosestBtRowIdToEvent(event)).val(true).trigger('change');
    return false;
  }

  function onSplitStatusChange(event){
    if ( $(event.target).val() == 'true'){
      showSplitsModal(event);
    } else {
      removeSplits(getClosestBtRowIdToEvent(event));
    }
  }

  function addRow(event) {
    var $invalid_fields = validateBankTransactionFields();
    if ($invalid_fields.length > 0) {
      showFieldIndicators($invalid_fields.parents('.form-group'));
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    setNextRowIdParameter($(event.currentTarget));
  }

  /* Understand- each batch row has an 'id' data
   attribute, and by using this you can construct the id for any particular
   row's fields. The same applies to the invoice modal. $('#id') is the fastest
   way for JQuery to find dom elements.
  */
  function setNextRowIdParameter($button) {
    var next_row_info = $('#next-row-id');
    checkTimeSinceLastClickAndContinue(next_row_info, function(){
      var row_id = next_row_info.val();
      var url = $button.attr('href').split('&')[0] + '&row_id='+row_id;
      $button.attr('href', url);
    })
  }

  function removeRow(event) {
    disableFormSubmit();
    applyMinimumRowsForForm({
      container: $('#bank-transaction-rows'),
      row_class: 'batch_row',
      add_new_class: 'bt_row'
    });
    releaseAttachmentsForNonPersistedRecord(event);
    removeClosestRow(event);
    calculateRunningBalances();
    return false;
  }

  function disableFormSubmit() {
    if ($('.batch_row').length > 1) { return; }
    disableFields($('#form-submit'));
  }

  function submit_row(event) {
    //window.bt_are_you_sure_controller.dispose();
    $('.submit_row_button').addClass('disabled');
    var formData = new FormData(document.querySelector('#imported_bank_transaction_form'));
    var row_id = getClosestBtRowIdToEvent(event);
    $('#bt__row'+row_id+' .submit_row_button').html("<i class='zmdi zmdi-rotate-right zmdi-hc-fw zmdi-hc-spin'></i>");
    $('#bt__row' + row_id).addClass('loading');
    var uuid = $('#UUID_' + row_id).val();
    formData.append('submitted_row_id', uuid);
    row_count = $('.batch_row').length
    $.ajax({
      method: 'post',
      url: $('#imported_bank_transaction_form').attr('action') + ".js?offset=" + row_count,
      processData: false,
      contentType: false,
      dataType: 'script',
      data: formData
    });
  }

  /**
   *  Clear hidden account selects for irrelevant types
   */
  function preprocessBatchFormForSubmit(e) {
    e.preventDefault();
    var $invalid_fields = validateBankTransactionFields();
    if ($invalid_fields.length > 0) {
      showFieldIndicators($invalid_fields.parents('.form-group'));
      return;
    }


    $(e.target).prop('disabled', 'disabled').text('Saving...');
    $('#bank_transaction_table_new').find('.split_currency_field').remove();
    // window.bt_are_you_sure_controller.dispose();
    $form = $('#imported_bank_transaction_form, #bank_transaction_form');

    if ( $form.length ){
      $form.trigger('submit.rails');
    }
  }

  /**
   * Functions managing the visibility of red underline indicators when some required information are missing.
   */
  window.BANK_TRANSACTION = {
    REQUIRED_FIELDS: '.account, .description_tf, .net_amount_field'
  }

  function validateBankTransactionFields() {
    var $fields = $(BANK_TRANSACTION.REQUIRED_FIELDS);
    hideFieldIndicators($fields.parents('.form-group'));
    return $fields.filter(function(index, element) {
      var is_split = $('#is_split_field'+getClosestBtRowIdToEvent(event)).val();
      if (is_split == "false") {
        return $(element).val() == "";
      }
    });
  }

  window.showFieldIndicators = function($elements) {
    $elements.addClass('required-field');
  }

  window.hideFieldIndicators = function($elements) {
    $elements.removeClass('required-field');
  }

  function hideIndicatorWhenMissingFieldIsSelected() {
    var $fields = $(BANK_TRANSACTION.REQUIRED_FIELDS);
    errors.tryOn($fields, 'focusin', function(e) {
      $(e.target).parents('.form-group').addClass('selected-required-field');
    });
    errors.tryOn($fields, 'focusout', function(e) {
      $(e.target).parents('.form-group').removeClass('selected-required-field');
    });
  }
})();
